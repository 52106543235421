import { useEffect } from "react";
import "./Home.scss";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-content" id="home">
      <h3>
        Wir entwickeln Lernsoftware - zugeschnitten auf deine nächste Prüfung.
      </h3>
      <div id="slogan">
        <h5>Einfach.</h5>
        <h5>Spielerisch.</h5>
        <h5>Effektiv.</h5>
      </div>
    </div>
  );
}
