import { useEffect } from "react";
import "./PrivacyPolicy.scss";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-content" id="privacy-policy">
      <p>
        1. Datenschutz auf einen Blick Allgemeine Hinweise Die folgenden
        Hinweise geben einen einfachen Überblick darüber, was mit Ihren
        personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung. Datenerfassung auf dieser Website Wer ist
        verantwortlich für die Datenerfassung auf dieser Website? Die
        Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
        Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur
        Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen. Wie
        erfassen wir Ihre Daten? Ihre Daten werden zum einen dadurch erhoben,
        dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten
        handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden
        automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch
        unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B.
        Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
        Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
        betreten. Wofür nutzen wir Ihre Daten? Ein Teil der Daten wird erhoben,
        um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
        Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden. Welche
        Rechte haben Sie bezüglich Ihrer Daten? Sie haben jederzeit das Recht,
        unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
        gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem
        ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
        Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können
        Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
        haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. 2 / 7 Des
        Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
        Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum Thema
        Datenschutz können Sie sich jederzeit an uns wenden. 2. Hosting All-Inkl
        Wir hosten unsere Website bei All-Inkl. Anbieter ist die ALL-INKL.COM –
        Neue Medien Münnich, Inh. René Münnich, Hauptstraße 68, 02742
        Friedersdorf (nachfolgend All-Inkl). Details entnehmen Sie der
        Datenschutzerklärung von All-Inkl:
        https://all-inkl.com/datenschutzinformationen/. Die Verwendung von
        All-Inkl erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben
        ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung
        unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde,
        erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1
        lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die
        Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
        des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
        Die Einwilligung ist jederzeit widerrufbar. 3. Allgemeine Hinweise und
        Pflichtinformationen Datenschutz Die Betreiber dieser Seiten nehmen den
        Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
        personenbezogenen Daten vertraulich und entsprechend den gesetzlichen
        Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
        diese Website benutzen, werden verschiedene personenbezogene Daten
        erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
        identifiziert werden können. Die vorliegende Datenschutzerklärung
        erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
        erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
        darauf hin, dass die Datenübertragung im Internet (z. B. bei der
        Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich. Hinweis zur verantwortlichen Stelle Die verantwortliche Stelle
        für die Datenverarbeitung auf dieser Website ist: Karidas Christian und
        Schmidt Julius GbR Karidas Christian und Schmidt Julius GbR Seestraße 53
        82211 Herrsching am Ammersee / OT Breitbrunn Telefon: +491735229303
        E-Mail: Support@Heatburst-Games.com Verantwortliche Stelle ist die
        natürliche oder juristische Person, die allein oder gemeinsam mit
        anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
        entscheidet. 3 / 7 Speicherdauer Soweit innerhalb dieser
        Datenschutzerklärung keine speziellere Speicherdauer genannt wurde,
        verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die
        Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen
        geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
        werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
        zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten
        haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
        letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
        Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
        dieser Website Sofern Sie in die Datenverarbeitung eingewilligt haben,
        verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6
        Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
        Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
        einer ausdrücklichen Einwilligung in die Übertragung personenbezogener
        Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf
        Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung
        von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B.
        via Device-Fingerprinting) eingewilligt haben, erfolgt die
        Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die
        Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
        Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1
        lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur
        Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf
        Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann
        ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1
        lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
        Rechtsgrundlagen wird in den folgenden Absätzen dieser
        Datenschutzerklärung informiert. Widerruf Ihrer Einwilligung zur
        Datenverarbeitung Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
        ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte
        Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
        Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO) WENN DIE DATENVERARBEITUNG AUF
        GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE
        JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
        SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
        WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
        GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
        VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
        WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN
        DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE
        SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE
        INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT
        DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
        (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN IHRE PERSONENBEZOGENEN
        DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS
        RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
        PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES
        GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
        VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN
        DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
        (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO). 4 / 7 Beschwerderecht bei der
        zuständigen Aufsichtsbehörde Im Falle von Verstößen gegen die DSGVO
        steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde,
        insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
        Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
        Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
        oder gerichtlicher Rechtsbehelfe. Recht auf Datenübertragbarkeit Sie
        haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
        Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an
        einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu
        lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist. SSL- bzw. TLS-Verschlüsselung Diese Seite nutzt aus
        Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte,
        wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
        Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine
        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol
        in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert
        ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten
        mitgelesen werden. Auskunft, Löschung und Berichtigung Sie haben im
        Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
        unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten,
        deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und
        ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
        zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
        jederzeit an uns wenden. Recht auf Einschränkung der Verarbeitung Sie
        haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
        folgenden Fällen: Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
        personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um
        dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Wenn die Verarbeitung Ihrer personenbezogenen Daten
        unrechtmäßig geschah/geschieht, können Sie statt der Löschung die
        Einschränkung der Datenverarbeitung verlangen. Wenn wir Ihre
        personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
        Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
        benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie einen
        Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
        Abwägung zwischen Ihren und unseren Interessen vorgenommen werden.
        Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie
        das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
        Daten zu verlangen. Wenn Sie die Verarbeitung Ihrer personenbezogenen
        Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
        abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
        oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Europäischen Union oder 5 / 7
        eines Mitgliedstaats verarbeitet werden. 4. Datenerfassung auf dieser
        Website Anfrage per E-Mail, Telefon oder Telefax Wenn Sie uns per
        E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive
        aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum
        Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
        verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar. Die von Ihnen an uns per
        Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur
        Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
        der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener
        Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
        insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
        Quelle: https://www.e-recht24.de
      </p>
    </div>
  );
}
