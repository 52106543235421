import { useEffect } from "react";
import "./About.scss";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-content" id="about">
      <h3>
        Wir sind ein kleines Entwickler-Team bestehend aus zwei Studenten der
        Technischen Universität München aus dem Bereich Informatik.
      </h3>
      <div id="slogan">
        <a
          href="https://www.linkedin.com/in/christian-karidas/"
          target="_blank"
        >
          <h5>Christian Karidas</h5>
        </a>
        <a
          href="https://www.linkedin.com/in/julius-schmidt-tum/"
          target="_blank"
        >
          <h5>Julius Schmidt</h5>
        </a>
      </div>
    </div>
  );
}
