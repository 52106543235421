import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer>
      <span>Copyright © 2023 Heatburst Technologies. All rights reserved.</span>
      <ul id="footer-links">
        <li>
          <Link to="/privacy-policy" className="footer-link">
            Datenschutzrichtlinie
          </Link>
        </li>
        <li>
          <Link to="/terms-and-conditions" className="footer-link">
            Geschäftsbedingungen
          </Link>
        </li>
      </ul>
    </footer>
  );
}
