import { useEffect } from "react";
import "./Contact.scss";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-content" id="contact">
      <h1 id="title">Impressum</h1>
      <h2>Angaben gemäß § 5 TMG</h2>
      <h3>Karidas Christian und Schmidt Julius GbR </h3>
      <h3>Seestraße 53</h3>
      <h3>82211 Bayern – Herrsching am Ammersee / OT Breitbrunn </h3>
      <h3>Vertreten durch:</h3>
      <h3>Julius schmidt und Christian Karidas</h3>
      <h1>Kontakt</h1>

      <h3>E-Mail: heatburst.technologies@gmail.com</h3>
      <h1>Verbraucher­streit­beilegung / Universal­schlichtungs­stelle</h1>
      <h3>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </h3>
      <h3>
        Quelle:{" "}
        <a href="https://www.e-recht24.de/" target="blank">
          e-recht24.de
        </a>
      </h3>
    </div>
  );
}
