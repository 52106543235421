import { NavLink } from "react-router-dom";
import "./Navbar.scss";
import { useEffect, useState } from "react";

export default function Navbar() {
  const [isCollapsedSectionVisible, setCollapsedSectionVisible] =
    useState(false);

  const closeCollapsedSection = () => {
    setCollapsedSectionVisible(false);
  };

  const toggleCollapsedSection = () => {
    setCollapsedSectionVisible(!isCollapsedSectionVisible);
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (!document.getElementById("navbar").contains(event.target)) {
        closeCollapsedSection();
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <nav id="navbar" className="fixed w-full z-20 top-0 left-0">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <NavLink
          to="/"
          className="flex items-center"
          onClick={closeCollapsedSection}
        >
          <img
            src={require("./../../assets/logo.png")}
            alt="heatburst logo"
            id="nav-logo"
          />
          <div id="nav-header">
            <p
              id="heatburst-header"
              className="self-center font-semibold font-montserrat whitespace-nowrap"
            >
              HEATBURST
            </p>
            <p
              id="technologies-header"
              className="self-center whitespace-nowrap"
            >
              Technologies
            </p>
          </div>
        </NavLink>
        <button
          onClick={toggleCollapsedSection}
          id="burger-menu"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none"
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isCollapsedSectionVisible ? "visible" : "hidden"
          } w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="flex flex-col p-4 md:p-0 md:flex-row md:space-x-8">
            <li onClick={toggleCollapsedSection}>
              <NavLink
                className="navlink block py-2 pl-3 pr-4 font-semibold"
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li onClick={toggleCollapsedSection}>
              <NavLink
                className="navlink block py-2 pl-3 pr-4 font-semibold"
                to="/about"
              >
                Über uns
              </NavLink>
            </li>
            <li onClick={toggleCollapsedSection}>
              <NavLink
                className="navlink block py-2 pl-3 pr-4 font-semibold"
                to="/contact"
              >
                Kontakt
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
